@import '../../icts-theme/src/utils/Variables.scss';
@import '../../icts-icon/src/utils/variables.scss';

.breadcrumb {
  height: 32px;
  display: flex;
  align-items: center;

  &__item {
    color: $color-gray-5;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    text-decoration: none;
    text-transform: capitalize;

    &--active {
      color: var(--color-primary);
    }

    &:first-child {
      text-transform: uppercase;
      font-size: $font-size-xs;
      letter-spacing: 0.1px;
    }
  }

  &__icon {
    transform: rotate(-90deg);
    padding: 0 $spacing-xs;

    &::before {
      color: $color-gray-5;
    }
  }
}
