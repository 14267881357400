@import '../../icts-theme/src/utils/Variables.scss';

.dropdown {
  top: 0;
  left: 0;
  outline: 0;
  width: auto;
  display: flex;
  padding: 8px 0;
  position: absolute;
  white-space: nowrap;
  flex-direction: column;
  background-color: $color-white;
  box-shadow: 0 2px 15px #E3E8F1;

  &--scrollable &__list {
    max-height: 220px;
    overflow-y: auto;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &__search {
    padding: 8px 16px;
    display: flex;
    position: relative;

    &-input {
      border: 0;
      width: 100%;
      padding: 8px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      border-radius: 5px;
      color: $color-gray-6;
      background-color: $color-gray-2;
    }
  }

  &__action {
    display: flex;
    padding: 8px 16px;
    position: relative;
    align-items: center;

    &-input {
      border: 0;
      outline: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding-right: 8px;
      color: var(--color-secondary);
      border-radius: 5px 0 0 5px;
    }

    &-icon {
      border: 0;
      cursor: pointer;

      &::before {
        color: var(--color-secondary);
      }
    }
  }

  &__item {
    outline: 0;
    height: 32px;
    display: flex;
    flex-shrink: 0;
    cursor: default;
    font-size: 14px;
    padding: 0 16px;
    font-weight: 400;
    line-height: 16px;
    position: relative;
    align-items: center;
    color: $color-gray-6;
    letter-spacing: .32px;
    justify-content: space-between;

    &-dropdown {
      display: none;
    }

    &-icon {
      opacity: 0;
      margin-left: 16px;
    }

    &--active {
      font-weight: 700;
      line-height: 20px;
      letter-spacing: .10px;
      color: var(--color-secondary);
    }

    &--active > &-icon {
      opacity: 1;
    }

    &--end {
      cursor: pointer;
    }

    &:focus:not(:hover) {
      outline: solid 1px $color-blue-3;
      box-shadow: 0 0 3px rgba(0, 118, 222, .5);
    }

    &:hover > & {
      &-dropdown {
        display: inherit;
      }

      &-icon {
        opacity: 1;
      }
    }

    &:hover {
      background-color: #EFF4F8;
    }
  }

  &--top &__item-dropdown {
    transform: translateY(calc(-100% + 40px));
  }

  &--bottom &__item-dropdown {
    top: -8px;
  }

  &--top &__item-dropdown,
  &--bottom &__item-dropdown {
    left: 100%;
  }
}
