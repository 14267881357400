@import '../../icts-theme/src/utils/Variables.scss';

.input-file {
  &__input {
    position: relative;

    .button {
      display: flex;
      position: relative;
    }
  }

  &__target {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: button;
    appearance: button;
  }

  &__uploaded {
    margin-top: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__action {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 8px;
  }
}
