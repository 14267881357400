/* ============ GRID ============ */
$grid-breakpoints: (
  xxs: 0,
  xs: 325px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  xs: 312px,
  sm: 360px,
  md: 716px,
  lg: 952px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-columns: 12 !default;
$grid-gutter-width: 16px;
$grid-row-columns: 6 !default;
$enable-grid-classes: true !default;
