@import "../../../../../../packages/icts-theme/src/utils/Variables.scss";

.dashboardviewer {
  &__question-table {
    margin-top: $spacing-md;
  }

  &__question-graph {
    width: 100%;

    p {
      margin-bottom: $spacing-xs;
    }

    &-legend {
      margin-top: $spacing-xs;
      ul {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        li {

          font-size: calc(0.9 * #{$font-size-xs});
          line-height: calc(0.9 * #{$line-height-xs});

          span {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 5px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  &__detalhes_title {
    background-color: darken($color-secondary, 5%);
    color: $color-white;
    text-align: center;
    padding: $spacing-md 0;
    font-size: $font-size-xl;
    margin: calc(2 * #{$spacing-xl}) 0 0 0;
  }
}
