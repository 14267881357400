@import '../../packages/icts-theme/src/utils/Variables.scss';

.menu {
  &__box {
    &--on_top {
      z-index: 20;
      a {
        color: $color-secondary !important;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}
