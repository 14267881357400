@import '../../icts-theme/src/utils/Variables.scss';

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 80px;
  margin-top: $spacing-xl;
  background-color: var(--color-secondary);
  display: flex;
  align-items: center;
  padding: $spacing-md;

  &__logo {
    height: 32px;
  }

  &__social {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-white;
    margin-left: $spacing-lg;

    &:first-child {
      margin-left: $spacing-xl;
    }
  }

  &__icon::before {
    color: $color-white;
  }

  &__value {
    margin-left: $spacing-xxs;
    font-size: $font-size-sm;
    line-height: $font-size-md;
  }
}
