$icomoon-font-family: "icts" !default;
$icomoon-font-path: "fonts" !default;

$adicionar: "\e900";
$agenda: "\e901";
$alerta: "\e902";
$alinhar-direita: "\e903";
$alinhar-esquerda: "\e904";
$android: "\e905";
$anexar: "\e906";
$angular: "\e907";
$apple: "\e908";
$apresentacao: "\e909";
$arquivar: "\e90a";
$arroba: "\e90b";
$atualizar: "\e90c";
$big-data: "\e90d";
$bitcoin: "\e90e";
$breadcrumb-fill: "\e90f";
$breadcrumb-home: "\e910";
$breadcrumb: "\e911";
$bug: "\e912";
$cadastro: "\e913";
$cafe: "\e914";
$calculadora: "\e915";
$calendario: "\e916";
$carrinho: "\e917";
$carteira: "\e918";
$centralizar: "\e919";
$chave: "\e91a";
$chrome: "\e91b";
$codigo: "\e91c";
$colar: "\e91d";
$comentario: "\e91e";
$compartilhar: "\e91f";
$compliance: "\e920";
$concluido: "\e921";
$configuracao-usuario: "\e922";
$configuracao: "\e923";
$configuracoes-de-dados: "\e924";
$cortar: "\e925";
$cronometro: "\e926";
$dashboard: "\e927";
$data: "\e928";
$desfazer: "\e929";
$documento: "\e92a";
$download: "\e92b";
$dropbox: "\e92c";
$duvida: "\e92d";
$editar: "\e92e";
$educacao: "\e92f";
$email: "\e930";
$empilhar: "\e931";
$enumerar: "\e932";
$enviar: "\e933";
$erro: "\e934";
$expandir: "\e935";
$exportar: "\e936";
$facebook: "\e937";
$favoritar: "\e938";
$fechar: "\e939";
$ferramentas: "\e93a";
$filmar: "\e93b";
$filtrar: "\e93c";
$foto: "\e93d";
$github: "\e93e";
$google: "\e93f";
$gostar: "\e940";
$grafico: "\e941";
$grupo-usuarios: "\e942";
$html5: "\e943";
$icts: "\e944";
$imagem: "\e945";
$imprimir: "\e946";
$informacao: "\e947";
$iniciar-pausar: "\e948";
$iniciar: "\e949";
$inovacao: "\e94a";
$insatisfeito: "\e94b";
$instagram: "\e94c";
$inteligencia-artificial: "\e94d";
$iot: "\e94e";
$italico: "\e94f";
$learning-machine: "\e950";
$ligar-desligar: "\e951";
$limpar: "\e952";
$link: "\e953";
$linkedin: "\e954";
$lista: "\e955";
$localizacao: "\e956";
$login: "\e957";
$logout: "\e958";
$manutencao: "\e959";
$marcador-bandeira: "\e95a";
$marcador-elemento: "\e95b";
$marcador: "\e95c";
$menos: "\e95d";
$mensagem: "\e95e";
$microfone-desligado: "\e95f";
$microfone: "\e960";
$monitores: "\e961";
$mover: "\e962";
$mudo: "\e963";
$musica: "\e964";
$nao-gostar: "\e965";
$nao-seguro: "\e966";
$negrito: "\e967";
$notificacao: "\e968";
$nuvem: "\e969";
$ocultar-notificacao: "\e96a";
$ocultar-visualizacao: "\e96b";
$opcoes: "\e96c";
$ordenar: "\e96d";
$parar: "\e96e";
$pasta-aberta: "\e96f";
$pasta-fechada: "\e970";
$pasta: "\e971";
$pausar: "\e972";
$pdf: "\e973";
$pesquisar: "\e974";
$preco: "\e975";
$processos: "\e976";
$proximo: "\e977";
$recortar: "\e978";
$recuar-direita: "\e979";
$recuar-esquerdaa: "\e97a";
$refazer: "\e97b";
$relogio: "\e97c";
$remover-formatacao: "\e97d";
$remover: "\e97e";
$resultado-negativo: "\e97f";
$resultado-positivo: "\e980";
$rpa: "\e981";
$salvar: "\e982";
$satisfeito: "\e983";
$seguro: "\e984";
$seta-baixo: "\e985";
$skype: "\e986";
$slack: "\e987";
$sublinhado: "\e988";
$suporte: "\e989";
$tag: "\e98a";
$tarefa: "\e98b";
$tela-inicial: "\e98c";
$telefone: "\e98d";
$testar: "\e98e";
$trabalho: "\e98f";
$trofeu: "\e990";
$twitter: "\e991";
$upload: "\e992";
$usuario-confirmado: "\e993";
$usuario: "\e994";
$vazio: "\e995";
$velocimetro: "\e996";
$video: "\e997";
$visualizar: "\e998";
$voltar: "\e999";
$volume-desligado: "\e99a";
$volume-reduzir: "\e99b";
$volume: "\e99c";
$whatsapp: "\e99d";
$wifi: "\e99e";
$windows: "\e99f";
$youtube: "\e9a0";
$zoom-mais: "\e9a1";
$zoom-menos: "\e9a2";

