@import "../packages/icts-theme/src/utils/Variables.scss";
@import "./reset.scss";

.main_content {
  padding-top: $spacing-xl;

  min-height: calc(100vh - 204px);
}


.spinner {
  > .spinner__wrapper {
    > .value {
      color: var(--color-primary);
      user-select: none;
    }
  }
}

.modal {
  &__header {
    > h2 {
      color: var(--color-primary);
      font-weight: 600;
      font-size: $font-size-lg;
      line-height: $line-height-lg;
    }
  }

  &__body {
    .modal__alert {
      font-size: $font-size-base;
      line-height: $line-height-base;

      &-icon {
        text-align: center;
        .icon-alerta::before {
          color: $color-warning;
        }
        .icon-erro::before {
          color: $color-error;
        }
      }

      :nth-child(2) {
        margin-top: $spacing-md;
      }

      :not(:first-child):not(:nth-child(2)) {
        margin-top: $spacing-xxs;
      }

      p {
        color: $color-gray-5;
        font-weight: 400;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
      }

      h4 {
        color: $color-gray-6;
        font-weight: 600;
      }
    }
  }
}
