@import '../../icts-theme/src/utils/Variables.scss';

.spinner {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-secondary, .25);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      animation: rotate 2s infinite linear;

      &::before {
        color: $color-white;
      }
    }

    .value {
      margin-top: $spacing-md;
      text-align: center;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}
