@import "../../../../../../packages/icts-theme/src/utils/Variables.scss";

.dashboardviewer {
  &__resultado_individual {
    margin: calc(2 * #{$spacing-xl}) 0 $spacing-xl 0;
    background-color: var(--color-primary);
    color: $color-white;
    text-align: center;
    padding: $spacing-md 0;
    font-size: $font-size-xl;
  }

  &__total_respondido {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: var(--color-secondary);
      font-weight: 400;
    }

    &_label {
      font-size: $font-size-md;
    }

    &_value {
      font-size: calc( 2 *#{$font-size-md});
    }
  }

  &__resultado_invalido {
    text-align: center;
    color: $color-error;
    font-weight: 400;
    font-size: $font-size-md;
    margin-bottom: $spacing-xl;
  }

  &__detalhes_title {
    color: $color-white;
    text-align: center;
    margin: $spacing-xl 0;
    padding: $spacing-md 0;
    font-size: $font-size-xl;
    background-color: var(--color-secondary);
  }

  &__score_risco {
    text-align: center;
    padding: $spacing-md 0;
    width: 100%;
  }

  &__analise {
    text-align: justify;
    width: 100%;
  }
}
