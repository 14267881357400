.form-date {
  position: relative;

  &__calendar {
    top: 100%;
    z-index: 2;
    margin-top: 12px;
    position: absolute;
  }
}
