@import '../../icts-theme/src/utils/Variables.scss';

.pagination {
  display: flex;

  &__info {
    font-weight: 400;
    color: $color-gray-5;
  }

  &__button {
    display: flex;
    transition: 0ms;
    min-width: 34px;
    margin-left: 16px;
    text-align: center;
    align-content: center;
    justify-content: center;

    &--left .icon {
      transform: rotate(90deg);
    }

    &--right .icon {
      transform: rotate(-90deg);
    }
  }
}
