@import './Mixins.scss';
@import './Functions.scss';
@import './Variables.scss';
@import './Breakpoints.scss';
@import './Container.scss';

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }

  .container-fluid {
    @include make-container();
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    .container-#{$breakpoint} {
      @extend .container-fluid;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      %responsive-container-#{$breakpoint} {
        max-width: $container-max-width;
      }

      @each $name, $width in $grid-breakpoints {
        @if ($container-max-width > $width or $breakpoint == $name) {
          .container#{breakpoint-infix($name, $grid-breakpoints)} {
            @extend %responsive-container-#{$breakpoint};
          }
        }
      }
    }
  }
}

@if $enable-grid-classes {
  .row {
    @include make-row();
  }
}


@if $enable-grid-classes {
  @include make-grid-columns();
}
