/* ============ COLORS ============ */
$color-secondary: #420080;

$color-blue: #2B5D7E;
$color-blue-1: #3E7898;
$color-blue-2: #CCEBFF;
$color-blue-3: #0076DE;

$color-white: #FFFFFF;
$color-gray-1: #F9F9F9;
$color-gray-2: #E9EEF3;
$color-gray-3: #E0E5EE;
$color-gray-4: #B6BACD;
$color-gray-5: #7F859D;
$color-gray-6: #414251;
$color-gray-background: #F6F9FB;

$color-error: #D74B29;
$color-warning: #FFB900;
$color-success: #00A28F;


/* ============ FONT ============ */
$font-family: 'Open Sans', sans-serif;
$font-size-xl: 28px;
$font-size-lg: 24px;
$font-size-md: 20px;
$font-size-base: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

$line-height-xl: 36px;
$line-height-lg: 32px;
$line-height-md: 28px;
$line-height-base: 24px;
$line-height-sm: 20px;
$line-height-xs: 16px;

$font-weight-base: 400;
$font-weight-semiBold: 600;

$font-style-italic: italic;

/* ============ BORDER ============ */
$border-radius-base: 4px;

/* ============ SPACING ============ */
$spacing-xxs: 8px;
$spacing-xs: 12px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 40px;

:root {
    --color-primary: #420080;
    --color-primary-1: #27004d;
    --color-primary-2: #1a0033;
    --color-secondary: #420080;
    --color-purple-6: #350066;
}
