@import '../../icts-theme/src/utils/Variables.scss';

.form-input {
  position: relative;
  width: 25%;

  &__label {
    display: block;
    margin-bottom: 8px;

    &-required {
      margin-left: 8px;
      color: $color-gray-6;
      font-style: italic;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__message {
    display: block;
    margin: 8px 0 0;
    color: inherit !important;
  }

  &__control {
    position: relative;
    display: flex;
  }

  &__append {
    border-radius: 0 5px 5px 0;
  }

  &__control--has-append &__target {
    border-radius: 5px 0 0 5px;
  }

  &__prepend  {
    border-radius: 5px 0 0 5px;

    &-wrapper {
      position: relative;
    }
  }

  &__control--has-prepend &__target {
    border-radius: 0 5px 5px 0;
  }

  &__control--has-prepend-and-append &__target {
    border-radius: 0 !important;
  }

  &__icon-default,
  &__icon-validation {
    top: 0;
    bottom: 0;
    right: 12px;
    display: flex;
    position: absolute;
    align-items: center;
  }

  &__icon-validation::before {
    color: inherit !important;
  }

  &__icon-default {
    &::before {
      color: var(--color-secondary);
    }

    &:focus {
      outline: 0;
    }
  }

  &__password {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &__target {
    outline: 0;
    width: 100%;
    padding: 12px;
    min-height: 50px;
    text-align: left;
    font-weight: $font-weight-semiBold;
    line-height: $line-height-base;
    border-radius: 5px;
    padding-right: 40px;
    color: $color-gray-6;
    border: 1px solid $color-gray-2;
    background-color: $color-gray-2;

    &-placeholder {
      cursor: inherit;
    }

    &-placeholder,
    &::placeholder {
      color: $color-gray-4;
    }

    &:focus {
      border-color: $color-blue-3;
      box-shadow: 0 0 3px rgba(0, 118, 222, .5);
    }

    &--sm {
      padding: 8px;
      font-size: 12px;
      min-height: 32px;
      line-height: 16px;
      padding-right: 28px;
    }
  }

  &__credit-card {
    display: flex;

    .form-input {
      &__target {
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-right: 12px;
      }

      &__card {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 2px solid $color-gray-2;
        display: flex;
        padding: 10px;

        &-flag {
          width: 24px;
        }
      }
    }
  }

  &--disabled {
    color: #E0E5EE;
  }

  &--disabled &__target {
    cursor: not-allowed;
    border-color: #E0E5EE;
    background-color: #E0E5EE;

    &-placeholder {
      color: #FFF;
    }
  }

  &--success {
    color: $color-success;
  }

  &--success &__target {
    border-color: $color-success;
  }

  &--warning {
    color: $color-warning;
  }

  &--warning &__target {
    border-color: $color-warning;
  }

  &--error {
    color: $color-error;
  }

  &--error &__target {
    border-color: $color-error;
  }

  &--note {
    color: $color-gray-5;
  }

  input[type="color"] {
    height: 48px;
    padding-right: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    &__target {
      padding: 8px;
      min-height: 42px;
      padding-right: 32px;
    }

    &__icon {
      top: 13px;
      right: 8px;
    }
  }
}
