*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
}

ol, ul {
  list-style: none;
}
