@import '../../icts-theme/src/utils/Variables.scss';

$columns: 12;
$padding: 14px;
$max_width: 100%;

.table {
  display: flex;
  width: $max_width;
  flex-direction: column;

  &__header {
    &-icon {
      font-size: 12px;
      margin-left: 8px;

      &--up {
        transform: rotate(180deg);
      }
    }

    &-row {
      display: flex;
      border-radius: 4px;
      color: $color-gray-5;
      font-size: $font-size-base;
      line-height: $line-height-sm;
      background-color: $color-gray-2;
    }

    &-item {
      display: flex;
      padding-top: 12px;
      align-items: center;
      padding-bottom: 12px;

      &--sortable {
        cursor: pointer;
      }

      &:not(:last-child) {
        border-right: 1px solid $color-gray-background;
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      position: relative;
      border-bottom: 1px solid #F0F2F5;

      &--selected {
        background-color: $color-blue-2 !important;

        &:before {
          top: 0;
          bottom: 0;
          width: 8px;
          content: '';
          position: absolute;
          background-color: var(--color-primary);
        }
      }

      &:nth-child(even) {
        background-color: $color-gray-background;
      }
    }

    &-item {
      display: flex;
      padding-top: 12px;
      align-items: center;
      padding-bottom: 12px;
      color: $color-gray-6;

      &:not(:last-child) {
        border-right: 1px solid $color-gray-2;
      }
    }
  }

  &__item {
    &--left {
      text-align: left;
      justify-content: flex-start;
    }

    &--center {
      text-align: center;
      justify-content: center;
    }

    &--right {
      text-align: right;
      justify-content: flex-end;
    }
  }

  @for $i from 1 through $columns {
    $half: calc($padding / 2);
    $width: calc($max_width / $columns * $i);

    .table__col--#{$i} {
      width: $width;
      padding-left: $half;
      padding-right: $half;
    }
  }

  .pagination {
    margin-top: 24px;
    align-self: flex-end;
  }
}
