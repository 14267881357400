@import "../../../packages/icts-theme/src/utils/Variables.scss";

.header {
  margin-bottom: $spacing-lg;
}

.dashboard-list-item {
  &:link,
  &:visited {
    text-decoration: none;
    display: inline-block;
    width: 100%;
    user-select: none;
    background-color: var(--color-primary);
    color: $color-white;
    text-transform: uppercase;
    padding: $spacing-lg;
    margin-bottom: $spacing-xs;
    border-radius: 2px;
    transition: all .2s;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      cursor: pointer;
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 3px 5px rgba(0,0,0,.4);
    }
  }
}
