.form-input__phone {
  .form-input {
    &__prepend {
      width: 82px;
      flex-direction: row-reverse;

      .button--icon {
        margin: 0;
      }

      .icon {
        margin-left: 8px;
      }
    }

    &__dropdown {
      width: 100%;
      top: 100%;
      // margin-top: calc(50% + 8px);
    }
  }
}
