@import "../../../../packages/icts-theme/src/utils/Variables.scss";

.dashboardviewer {
  &__print_header {
    height: 100px;
    display: flex;
    align-items: center;
    margin: -32px -32px 0px -5px;

    img {
      height: 40px;
    }
  }

  &__print {
    max-width: 794px;

    .dashboardviewer__print_header {
      display: flex;
    }
  }

  &__card_top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-lg;
    justify-content: space-between;

    button {
      border: none;
      outline: none;
      cursor: pointer;
      background: none;

      i::before {
        color: var(--color-primary)
      }
    }
  }

  &__filtros {
    &:not(:nth-child(2)) {
      margin-top: $spacing-md;
    }
  }

  &__select {
    z-index: 5;

    &--full_width {
      width: 100%;
    }

    &--on_top {
      z-index: 15;
    }

    &--between {
      z-index: 10;
    }
  }

  &__btn_container {
    display: flex;
    flex-direction: column-reverse;

    button {
      display: flex;
      justify-content: center;

      @media (max-width: 1200px) {
        & {
          margin-top: $spacing-sm;
        }
      }
    }
  }

  &__termo {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-style: italic;
    margin-top: $spacing-md;
  }
}
