@import "./utils/variables.scss";
@import "../../icts-theme/src/utils/Variables.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('./utils/#{$icomoon-font-path}/#{$icomoon-font-family}.eot?29nuvx');
  src:  url('./utils/#{$icomoon-font-path}/#{$icomoon-font-family}.eot?29nuvx#iefix') format('embedded-opentype'),
    url('./utils/#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?29nuvx') format('truetype'),
    url('./utils/#{$icomoon-font-path}/#{$icomoon-font-family}.woff?29nuvx') format('woff'),
    url('./utils/#{$icomoon-font-path}/#{$icomoon-font-family}.svg?29nuvx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


.icon {
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--xs {
    font-size: $font-size-base;
  }
  &--sm {
    font-size: $font-size-lg;
  }
  &--md {
    font-size: 32px;
  }
  &--lg {
    font-size: 48px;
  }
}

.icon-adicionar {
  &:before {
    content: $adicionar;
    color: #2b5d7e;
  }
}
.icon-agenda {
  &:before {
    content: $agenda;
    color: #2b5d7e;
  }
}
.icon-alerta {
  &:before {
    content: $alerta;
    color: #2b5d7e;
  }
}
.icon-alinhar-direita {
  &:before {
    content: $alinhar-direita;
    color: #2b5d7e;
  }
}
.icon-alinhar-esquerda {
  &:before {
    content: $alinhar-esquerda;
    color: #2b5d7e;
  }
}
.icon-android {
  &:before {
    content: $android;
    color: #2b5d7e;
  }
}
.icon-anexar {
  &:before {
    content: $anexar;
    color: #2b5d7e;
  }
}
.icon-angular {
  &:before {
    content: $angular;
    color: #2b5d7e;
  }
}
.icon-apple {
  &:before {
    content: $apple;
    color: #2b5d7e;
  }
}
.icon-apresentacao {
  &:before {
    content: $apresentacao;
    color: #2b5d7e;
  }
}
.icon-arquivar {
  &:before {
    content: $arquivar;
    color: #2b5d7e;
  }
}
.icon-arroba {
  &:before {
    content: $arroba;
    color: #2b5d7e;
  }
}
.icon-atualizar {
  &:before {
    content: $atualizar;
    color: #2b5d7e;
  }
}
.icon-big-data {
  &:before {
    content: $big-data;
    color: #2b5d7e;
  }
}
.icon-bitcoin {
  &:before {
    content: $bitcoin;
    color: #2b5d7e;
  }
}
.icon-breadcrumb-fill {
  &:before {
    content: $breadcrumb-fill;
    color: #f08523;
  }
}
.icon-breadcrumb-home {
  &:before {
    content: $breadcrumb-home;
    color: #f08523;
  }
}
.icon-breadcrumb {
  &:before {
    content: $breadcrumb;
    color: #f08523;
  }
}
.icon-bug {
  &:before {
    content: $bug;
    color: #2b5d7e;
  }
}
.icon-cadastro {
  &:before {
    content: $cadastro;
    color: #2b5d7e;
  }
}
.icon-cafe {
  &:before {
    content: $cafe;
    color: #2b5d7e;
  }
}
.icon-calculadora {
  &:before {
    content: $calculadora;
    color: #2b5d7e;
  }
}
.icon-calendario {
  &:before {
    content: $calendario;
    color: #2b5d7e;
  }
}
.icon-carrinho {
  &:before {
    content: $carrinho;
    color: #2b5d7e;
  }
}
.icon-carteira {
  &:before {
    content: $carteira;
    color: #2b5d7e;
  }
}
.icon-centralizar {
  &:before {
    content: $centralizar;
    color: #2b5d7e;
  }
}
.icon-chave {
  &:before {
    content: $chave;
    color: #2b5d7e;
  }
}
.icon-chrome {
  &:before {
    content: $chrome;
    color: #2b5d7e;
  }
}
.icon-codigo {
  &:before {
    content: $codigo;
    color: #2b5d7e;
  }
}
.icon-colar {
  &:before {
    content: $colar;
    color: #2b5d7e;
  }
}
.icon-comentario {
  &:before {
    content: $comentario;
    color: #2b5d7e;
  }
}
.icon-compartilhar {
  &:before {
    content: $compartilhar;
    color: #2b5d7e;
  }
}
.icon-compliance {
  &:before {
    content: $compliance;
    color: #00426b;
  }
}
.icon-concluido {
  &:before {
    content: $concluido;
    color: #2b5d7e;
  }
}
.icon-configuracao-usuario {
  &:before {
    content: $configuracao-usuario;
    color: #2b5d7e;
  }
}
.icon-configuracao {
  &:before {
    content: $configuracao;
    color: #2b5d7e;
  }
}
.icon-configuracoes-de-dados {
  &:before {
    content: $configuracoes-de-dados;
    color: #2b5d7e;
  }
}
.icon-cortar {
  &:before {
    content: $cortar;
    color: #2b5d7e;
  }
}
.icon-cronometro {
  &:before {
    content: $cronometro;
    color: #2b5d7e;
  }
}
.icon-dashboard {
  &:before {
    content: $dashboard;
    color: #2b5d7e;
  }
}
.icon-data {
  &:before {
    content: $data;
    color: #2b5d7e;
  }
}
.icon-desfazer {
  &:before {
    content: $desfazer;
    color: #2b5d7e;
  }
}
.icon-documento {
  &:before {
    content: $documento;
    color: #2b5d7e;
  }
}
.icon-download {
  &:before {
    content: $download;
    color: #2b5d7e;
  }
}
.icon-dropbox {
  &:before {
    content: $dropbox;
    color: #2b5d7e;
  }
}
.icon-duvida {
  &:before {
    content: $duvida;
    color: #00426b;
  }
}
.icon-editar {
  &:before {
    content: $editar;
    color: #2b5d7e;
  }
}
.icon-educacao {
  &:before {
    content: $educacao;
    color: #2b5d7e;
  }
}
.icon-email {
  &:before {
    content: $email;
    color: #2b5d7e;
  }
}
.icon-empilhar {
  &:before {
    content: $empilhar;
    color: #2b5d7e;
  }
}
.icon-enumerar {
  &:before {
    content: $enumerar;
    color: #2b5d7e;
  }
}
.icon-enviar {
  &:before {
    content: $enviar;
    color: #2b5d7e;
  }
}
.icon-erro {
  &:before {
    content: $erro;
    color: #2b5d7e;
  }
}
.icon-expandir {
  &:before {
    content: $expandir;
    color: #2b5d7e;
  }
}
.icon-exportar {
  &:before {
    content: $exportar;
    color: #ccebff;
  }
}
.icon-facebook {
  &:before {
    content: $facebook;
    color: #2b5d7e;
  }
}
.icon-favoritar {
  &:before {
    content: $favoritar;
    color: #2b5d7e;
  }
}
.icon-fechar {
  &:before {
    content: $fechar;
    color: #2b5d7e;
  }
}
.icon-ferramentas {
  &:before {
    content: $ferramentas;
    color: #2b5d7e;
  }
}
.icon-filmar {
  &:before {
    content: $filmar;
    color: #2b5d7e;
  }
}
.icon-filtrar {
  &:before {
    content: $filtrar;
    color: #2b5d7e;
  }
}
.icon-foto {
  &:before {
    content: $foto;
    color: #2b5d7e;
  }
}
.icon-github {
  &:before {
    content: $github;
    color: #2b5d7e;
  }
}
.icon-google {
  &:before {
    content: $google;
    color: #2b5d7e;
  }
}
.icon-gostar {
  &:before {
    content: $gostar;
    color: #2b5d7e;
  }
}
.icon-grafico {
  &:before {
    content: $grafico;
    color: #2b5d7e;
  }
}
.icon-grupo-usuarios {
  &:before {
    content: $grupo-usuarios;
    color: #2b5d7e;
  }
}
.icon-html5 {
  &:before {
    content: $html5;
    color: #2b5d7e;
  }
}
.icon-icts {
  &:before {
    content: $icts;
    color: #00426b;
  }
}
.icon-imagem {
  &:before {
    content: $imagem;
    color: #2b5d7e;
  }
}
.icon-imprimir {
  &:before {
    content: $imprimir;
    color: #2b5d7e;
  }
}
.icon-informacao {
  &:before {
    content: $informacao;
    color: #2b5d7e;
  }
}
.icon-iniciar-pausar {
  &:before {
    content: $iniciar-pausar;
    color: #2b5d7e;
  }
}
.icon-iniciar {
  &:before {
    content: $iniciar;
    color: #2b5d7e;
  }
}
.icon-inovacao {
  &:before {
    content: $inovacao;
    color: #00426b;
  }
}
.icon-insatisfeito {
  &:before {
    content: $insatisfeito;
    color: #2b5d7e;
  }
}
.icon-instagram {
  &:before {
    content: $instagram;
    color: #2b5d7e;
  }
}
.icon-inteligencia-artificial {
  &:before {
    content: $inteligencia-artificial;
    color: #00426b;
  }
}
.icon-iot {
  &:before {
    content: $iot;
    color: #00426b;
  }
}
.icon-italico {
  &:before {
    content: $italico;
    color: #2b5d7e;
  }
}
.icon-learning-machine {
  &:before {
    content: $learning-machine;
    color: #2b5d7e;
  }
}
.icon-ligar-desligar {
  &:before {
    content: $ligar-desligar;
    color: #2b5d7e;
  }
}
.icon-limpar {
  &:before {
    content: $limpar;
    color: #2b5d7e;
  }
}
.icon-link {
  &:before {
    content: $link;
    color: #2b5d7e;
  }
}
.icon-linkedin {
  &:before {
    content: $linkedin;
    color: #2b5d7e;
  }
}
.icon-lista {
  &:before {
    content: $lista;
    color: #2b5d7e;
  }
}
.icon-localizacao {
  &:before {
    content: $localizacao;
    color: #2b5d7e;
  }
}
.icon-login {
  &:before {
    content: $login;
    color: #00426b;
  }
}
.icon-logout {
  &:before {
    content: $logout;
    color: #00426b;
  }
}
.icon-manutencao {
  &:before {
    content: $manutencao;
    color: #2b5d7e;
  }
}
.icon-marcador-bandeira {
  &:before {
    content: $marcador-bandeira;
    color: #2b5d7e;
  }
}
.icon-marcador-elemento {
  &:before {
    content: $marcador-elemento;
    color: #2b5d7e;
  }
}
.icon-marcador {
  &:before {
    content: $marcador;
    color: #2b5d7e;
  }
}
.icon-menos {
  &:before {
    content: $menos;
    color: #fff;
  }
}
.icon-mensagem {
  &:before {
    content: $mensagem;
    color: #2b5d7e;
  }
}
.icon-microfone-desligado {
  &:before {
    content: $microfone-desligado;
    color: #2b5d7e;
  }
}
.icon-microfone {
  &:before {
    content: $microfone;
    color: #2b5d7e;
  }
}
.icon-monitores {
  &:before {
    content: $monitores;
    color: #2b5d7e;
  }
}
.icon-mover {
  &:before {
    content: $mover;
    color: #2b5d7e;
  }
}
.icon-mudo {
  &:before {
    content: $mudo;
    color: #2b5d7e;
  }
}
.icon-musica {
  &:before {
    content: $musica;
    color: #2b5d7e;
  }
}
.icon-nao-gostar {
  &:before {
    content: $nao-gostar;
    color: #2b5d7e;
  }
}
.icon-nao-seguro {
  &:before {
    content: $nao-seguro;
    color: #2b5d7e;
  }
}
.icon-negrito {
  &:before {
    content: $negrito;
    color: #2b5d7e;
  }
}
.icon-notificacao {
  &:before {
    content: $notificacao;
    color: #2b5d7e;
  }
}
.icon-nuvem {
  &:before {
    content: $nuvem;
    color: #2b5d7e;
  }
}
.icon-ocultar-notificacao {
  &:before {
    content: $ocultar-notificacao;
    color: #2b5d7e;
  }
}
.icon-ocultar-visualizacao {
  &:before {
    content: $ocultar-visualizacao;
    color: #2b5d7e;
  }
}
.icon-opcoes {
  &:before {
    content: $opcoes;
    color: #2b5d7e;
  }
}
.icon-ordenar {
  &:before {
    content: $ordenar;
    color: #00426b;
  }
}
.icon-parar {
  &:before {
    content: $parar;
    color: #2b5d7e;
  }
}
.icon-pasta-aberta {
  &:before {
    content: $pasta-aberta;
    color: #2b5d7e;
  }
}
.icon-pasta-fechada {
  &:before {
    content: $pasta-fechada;
    color: #2b5d7e;
  }
}
.icon-pasta {
  &:before {
    content: $pasta;
    color: #2b5d7e;
  }
}
.icon-pausar {
  &:before {
    content: $pausar;
    color: #2b5d7e;
  }
}
.icon-pdf {
  &:before {
    content: $pdf;
    color: #2b5d7e;
  }
}
.icon-pesquisar {
  &:before {
    content: $pesquisar;
    color: #2b5d7e;
  }
}
.icon-preco {
  &:before {
    content: $preco;
    color: #2b5d7e;
  }
}
.icon-processos {
  &:before {
    content: $processos;
    color: #2b5d7e;
  }
}
.icon-proximo {
  &:before {
    content: $proximo;
    color: #2b5d7e;
  }
}
.icon-recortar {
  &:before {
    content: $recortar;
    color: #2b5d7e;
  }
}
.icon-recuar-direita {
  &:before {
    content: $recuar-direita;
    color: #2b5d7e;
  }
}
.icon-recuar-esquerdaa {
  &:before {
    content: $recuar-esquerdaa;
    color: #2b5d7e;
  }
}
.icon-refazer {
  &:before {
    content: $refazer;
    color: #2b5d7e;
  }
}
.icon-relogio {
  &:before {
    content: $relogio;
    color: #2b5d7e;
  }
}
.icon-remover-formatacao {
  &:before {
    content: $remover-formatacao;
    color: #2b5d7e;
  }
}
.icon-remover {
  &:before {
    content: $remover;
    color: #2b5d7e;
  }
}
.icon-resultado-negativo {
  &:before {
    content: $resultado-negativo;
    color: #2b5d7e;
  }
}
.icon-resultado-positivo {
  &:before {
    content: $resultado-positivo;
    color: #2b5d7e;
  }
}
.icon-rpa {
  &:before {
    content: $rpa;
    color: #2b5d7e;
  }
}
.icon-salvar {
  &:before {
    content: $salvar;
    color: #00426b;
  }
}
.icon-satisfeito {
  &:before {
    content: $satisfeito;
    color: #2b5d7e;
  }
}
.icon-seguro {
  &:before {
    content: $seguro;
    color: #2b5d7e;
  }
}
.icon-seta-baixo {
  &:before {
    content: $seta-baixo;
    color: #2b5d7e;
  }
}
.icon-skype {
  &:before {
    content: $skype;
    color: #2b5d7e;
  }
}
.icon-slack {
  &:before {
    content: $slack;
  }
}
.icon-sublinhado {
  &:before {
    content: $sublinhado;
    color: #2b5d7e;
  }
}
.icon-suporte {
  &:before {
    content: $suporte;
    color: #2b5d7e;
  }
}
.icon-tag {
  &:before {
    content: $tag;
    color: #2b5d7e;
  }
}
.icon-tarefa {
  &:before {
    content: $tarefa;
    color: #2b5d7e;
  }
}
.icon-tela-inicial {
  &:before {
    content: $tela-inicial;
    color: #2b5d7e;
  }
}
.icon-telefone {
  &:before {
    content: $telefone;
    color: #2b5d7e;
  }
}
.icon-testar {
  &:before {
    content: $testar;
    color: #2b5d7e;
  }
}
.icon-trabalho {
  &:before {
    content: $trabalho;
    color: #2b5d7e;
  }
}
.icon-trofeu {
  &:before {
    content: $trofeu;
    color: #2b5d7e;
  }
}
.icon-twitter {
  &:before {
    content: $twitter;
    color: #2b5d7e;
  }
}
.icon-upload {
  &:before {
    content: $upload;
    color: #2b5d7e;
  }
}
.icon-usuario-confirmado {
  &:before {
    content: $usuario-confirmado;
    color: #2b5d7e;
  }
}
.icon-usuario {
  &:before {
    content: $usuario;
    color: #2b5d7e;
  }
}
.icon-vazio {
  &:before {
    content: $vazio;
    color: #00426b;
  }
}
.icon-velocimetro {
  &:before {
    content: $velocimetro;
    color: #2b5d7e;
  }
}
.icon-video {
  &:before {
    content: $video;
    color: #2b5d7e;
  }
}
.icon-visualizar {
  &:before {
    content: $visualizar;
    color: #2b5d7e;
  }
}
.icon-voltar {
  &:before {
    content: $voltar;
    color: #2b5d7e;
  }
}
.icon-volume-desligado {
  &:before {
    content: $volume-desligado;
    color: #2b5d7e;
  }
}
.icon-volume-reduzir {
  &:before {
    content: $volume-reduzir;
    color: #2b5d7e;
  }
}
.icon-volume {
  &:before {
    content: $volume;
    color: #2b5d7e;
  }
}
.icon-whatsapp {
  &:before {
    content: $whatsapp;
    color: #2b5d7e;
  }
}
.icon-wifi {
  &:before {
    content: $wifi;
    color: #00426b;
  }
}
.icon-windows {
  &:before {
    content: $windows;
    color: #2b5d7e;
  }
}
.icon-youtube {
  &:before {
    content: $youtube;
    color: #2b5d7e;
  }
}
.icon-zoom-mais {
  &:before {
    content: $zoom-mais;
    color: #2b5d7e;
  }
}
.icon-zoom-menos {
  &:before {
    content: $zoom-menos;
    color: #2b5d7e;
  }
}
