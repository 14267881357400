@import '../../icts-theme/src/utils/Variables.scss';

@mixin rangeThumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin: -8px 0  0;
  cursor: pointer;
  transition: all .3s ease-in-out;
  background-color: var(--color-secondary);
}

@mixin rangeTrack {
  width: 100%;
  height: 8px;
  background-color: transparent;
  border-radius: 16px;
}

.range-input {

  input[type="range"] {
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include rangeThumb;
    }

    &::-moz-range-thumb {
      @include rangeThumb;
    }

    &::-ms-thumb {
      @include rangeThumb;
    }

    &::-webkit-slider-runnable-track {
      @include rangeTrack;
    }

    &::-moz-range-track {
      @include rangeTrack;
    }

    &::-ms-track {
      @include rangeTrack;
    }

    &:focus {
      background: none;
      outline: none;
    }
  }

  &__inner {
    width: 100%;
    z-index: 3;
    position: relative;
    background-color: transparent;
  }

  &__target {
    position: relative;

    &::before {
      content: '';
      height: 8px;
      width: 100%;
      border-radius: 16px;
      background-color: $color-gray-2;
      position: absolute;
      bottom: 2px;
      z-index: 1;
    }
  }

  &__bar {
    display: flex;
    position: absolute;
    top: 8px;
    z-index: 2;
  }

  &__progress {
    background-color: $color-warning;
    height: 8px;
    border-radius: 16px;
    width: 100%;
  }

  &__tooltip {
    position: absolute;
    top: -48px;
    right: -16px;
    background-color: var(--color-secondary);
    padding: 0 8px;
    border-radius: 4px;

    &::before {
      width: 0;
      height: 0;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      position: absolute;
      border-top: 8px solid var(--color-secondary);
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    &-value {
      color: $color-white;
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
}
